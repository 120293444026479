//判断字符串返回ASCAII码值
export function getStringLengthByDeviceFormat(str) {
  if (str == null || str == "") {
    return 0;
  }
  var strLength = 0.0;
  for (var i = 0; i < str.length; i++) {
    if (str.charCodeAt(i) < 127) {
      strLength = strLength + 0.33;
    } else {
      strLength++;
    }
  }
  return strLength;
}

//保留两位小数
export function formmatNumFix2(num, num1) {
  if (num == null && num1 == null) return "0.00";
  else if (num != null && num1 == null) return (num * 0.01).toFixed(2);
  else if (num == null && num1 != null) return (num1 * 0.01).toFixed(2);
  else return ((num + num1) * 0.01).toFixed(2);
}

export function formmatNumFixN(num, num1,useGrouping=true,minimumFractionDigits) {
  // num：原数  num1：最多需要保留几位小数
  num = num?num*1:0;
  num1 = num1?num1*1:0;
  let options = {
    maximumFractionDigits:num1,
    useGrouping:useGrouping,
  }
  minimumFractionDigits && (options.minimumFractionDigits = minimumFractionDigits);
  return new Intl.NumberFormat('zh-CN',options).format(num);
}

export function getChildData(data, param) {
  var info = data;
  var array = param.split(".");
  for (var i = 0; i < array.length; i++) {
    var p = array[i];
    if (p.indexOf("[") > 0) {
      var first = p.indexOf("[");
      var end = p.indexOf("]");
      var p1 = p.substring(0, first);
      var p2 = p.substring(first + 1, end);
      info = info[p1];
      if (info == null) {
        break;
      } else {
        info = info[parseInt(p2)];
        if (info == null) {
          break;
        }
      }
    } else {
      info = info[p];
      if (info == null) {
        break;
      }
    }
  }
  return info;
}

export function uuid() {
  var s = [];
  var hexDigits = "0123456789abcdef";
  for (var i = 0; i < 36; i++) {
      s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
  }
  s[14] = "4";
  s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1);
  s[8] = s[13] = s[18] = s[23] = "-";
  var uuid = s.join("");
  return uuid;
}

export function formatEdition(val) {
  var arr = new Array();
  if (val == null) return arr;
  var lastKey = null;
  var outObj = null;
  var monthArr = null;
  for (var i = 0; i < val.length; i++) {
    var tmp = val[i];
    var thisKey = parseInt(tmp.substring(0, 4));
    var thisVal = parseInt(tmp.substring(4, 6));

    if (lastKey != thisKey) {
      if (outObj != null) {
        arr.push(outObj);
      }
      outObj = new Object();
      outObj.year = thisKey;
      monthArr = new Array();
      monthArr.push(thisVal);
      outObj.monthlist = monthArr;
      lastKey = thisKey;
    } else {
      monthArr = outObj.monthlist;
      monthArr.push(thisVal);
    }
  }
  arr.push(outObj);
  return arr;
}

export function generateMixed(n) {
  var res = "";
  for (var i = 0; i < n; i++) {
    var id = Math.ceil(Math.random() * 35);
    res += jschars[id];
  }
  return res;
}
//校验邮箱
export function validEmail(email) {
  const reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return reg.test(email)
}
//格式化金额
export function formatMoney(val) {
  var money = ((+val) / 100).toFixed(2)
  if(Math.abs(money) == 0) {
    return '0.00'
  } else {
    var show = (+money).toLocaleString('en-US') //千分位转换
    if (isNaN(Number(money))) {
      return val
    }
    if(show.indexOf('.') == -1){
      return show + '.00'
    }
    if(show.length - show.indexOf('.') == 2){
      return show + '0'
    }
    return show
  }
}
//替换手机号中间四位
export function replacePhone(num) {
  let mphone = num;
  if (mphone) {
    mphone = num.substr(0, 3) + '****' + num.substr(7);
  }
  return mphone;
};

// 防抖
export function debounce(func, wait=1000, immediate=false) {
  let timeout
  return function(...args) {
      let context = this
      if (timeout) clearTimeout(timeout)
      if (immediate) {
          let callNow = !timeout
          timeout = setTimeout(function() {
              timeout = null
          }, wait)
          if (callNow) func.apply(context, args)
      } else {
          timeout = setTimeout(function() {
              func.apply(context, args)
          }, wait)
      }
  }
};
// 金额3位加逗号
export function formateMoney(num) {
  if (!num) num = 0;
  if((num+'').indexOf('.')==-1){
    num = num.toFixed(2)
  }
  let c = num.split('.')
  let b = c[0].toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') + '.' + c[1]
  return b
};

//数字精度问题
export function strip(num, precision = 12) {
  return +parseFloat(num.toPrecision(precision));
}
//判断小数点位数
export function countDecimalPlaces(num) {
  var decimalPart = num.toString().split('.')[1];
  if (decimalPart) {
    return decimalPart.length;
  } else {
    return 0;
  }
}
//合并相同键对象
export function mergeObjects(a, b) {
  let mergedObj = {};
  for (let key in a) {
    if (b.hasOwnProperty(key) && Array.isArray(b[key])) {
      mergedObj[key] = { ...a[key], children: b[key] };
    }
  }
  return mergedObj;
}
export function updateStatus(obj) {
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      let hasError = obj[key].children.some(item => item.status === 1 || item.status === 2);
      obj[key].status = hasError ? 1 : 0;
    }
  }
  return obj;
}

// 将对象转为查询字符串；eg: {a:1,b:2} => a=1&b=2
export const convertToQueryString = (obj) => {
  return Object.entries(obj)  // 将对象转为二维数组 [key, value] 形式
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)  // 对每一对键值进行编码并转换为 "key=value" 格式
    .join('&');  // 将所有键值对连接起来，以 & 分隔
};