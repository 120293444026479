export default {
    not_begin: "未开始",
    in_progress: "进行中",
    paused: "已暂停",
    stopped: "已结束",
    all: "全部",
    promotion_type1: "充值赠送(现金)",
    promotion_type2: "充值赠送(现金+优惠券)",
    promotion_type3: "充值赠送(优惠券)",
    promotion_type4: "赠送优惠券",
    promotion_type5: "销售优惠券",
    copy_link_success:"复制外链成功",
    member_00006: '开',
    member_00007: '关',
    handle_giv: "手动赠送",
    activate_limit:"活动限制条件",
    user_scope:"参与活动用户范围",
    level_giv:"直接赠送",
    add_givuser_text1: "要赠送的用户",
    add_givuser_text2: "还未添加要赠送的用户",
    giv: "赠送",
    giv_error_info:"失败原因",
    card_search_giv: "请输入昵称、手机号查找要赠送的用户",
    search: "查找",
    head_portrait: "头像",
    nickname: "昵称",
    cell_phone_number: "手机号",
    success_giv: "赠送成功",
    success_giv1: "赠送成功！",
    error_giv: "赠送失败",
    stored_00003: '活动名称',
    stored_00004: '活动状态',
    stored_00007: '活动列表',
    stored_00008: '新建',
    stored_00009: '活动名称',
    stored_00010: '活动时间',
    stored_00011: '活动状态',
    stored_00012: '活动入口',
    stored_00013: '活动外发专用链接',
    stored_00014: '复制链接',
    stored_00015: '是否展示',
    stored_00016: '车主端-我的钱包-充值',
    stored_00018: '详情',
    stored_00019: '手动赠送',
    stored_00020: '储值送返活动',
    stored_00021: '自由领取活动',
    stored_00022: '活动ID',
    stored_00023: '活动入口(公众号)',
    stored_00024: '生效时段',
    stored_00025: '全天',
    stored_00026: '打开展示开关，车主端（公众号/小程序）中“我的钱包-充值”可支持展示多个“未结束”的储值送返活动。',
    stored_00027: '车主端（公众号/小程序）-我的钱包-充值入口',
    stored_00028: '车主端（公众号/小程序）预览',
    stored_00029: '鼠标选中上下拖拽活动卡片可改变展示顺序',
    stored_00030: '充值',
    stored_00031: '储值活动',
    stored_00032: '不支持退款',
    stored_00033: '支持退款',
    stored_00034: '还没有储值活动<br/>敬请期待',
    stored_00035: '',
    stored_00036: '',
    stored_00037: '',
    stored_00038: '',
    stored_00039: '',
    stored_00040: '',

}
