export default {
    notify_00001: 'Notiz',
    notify_00002: 'Alle Benachrichtigungen',
    notify_00003: 'Jahr',
    notify_00004: 'Mond',
    notify_00005: 'In Rechnung gestellt',
    notify_00006: 'Abgelehnt',
    notify_00007: 'Sie können den Grund überprüfen und sich erneut bewerben',
    notify_00008: 'Bitte überprüfen Sie Ihre reservierte Telefonnummer oder E-Mail',
    notify_00009: `Billing Notification: Your platform usage fee <span style="color:#09B59D">deduction successful</span>, the bill has been paid. <a href="javascript:void(0)" onclick="vm.readMessageAndJump('./selfHelpPayCost.html?id={id}#platformBillings' ,'{messageId}')">View</a>`,
    notify_00010: `Billing Notification: Your IoT card network fee <span style="color:#09B59D">deduction successful</span>, the bill has been paid. <a href="javascript:void(0)" onclick="vm.readMessageAndJump('./selfHelpPayCost.html?id={id}#platformBillings' ,'{messageId}')">View</a>`,
    notify_00011: `Billing Notification: Your platform usage fee <span style="color:#FA545E">deduction failed</span>, the bill is <span style="color:#FF9F0A">pending payment</span>, with <span style="color:#FA545E">3</span> days remaining. Please pay in time to avoid service interruption. <a href="javascript:void(0)" onclick="vm.readMessageAndJump('./selfHelpPayCost.html?id={id}#platformBillings' ,'{messageId}')">View</a>`,
    notify_00012: `Billing Notification: Your platform usage fee <span style="color:#FA545E">deduction failed</span>, the bill is <span style="color:#FA545E">overdue</span>. Please pay within <span style="color:#FA545E">{day}</span> days to avoid service interruption. <a href="javascript:void(0)" onclick="vm.readMessageAndJump('./selfHelpPayCost.html?id={id}#platformBillings' ,'{messageId}')">View</a>`,
    notify_00013: `Billing Notification: Your IoT card network fee <span style="color:#FA545E">deduction failed</span>, the bill is <span style="color:#FA545E">overdue</span>. Please pay in time. <a href="javascript:void(0)" onclick="vm.readMessageAndJump('./selfHelpPayCost.html?id={id}#platformBillings' ,'{messageId}')">View</a>`,
    notify_00014: `Invoice Processing Notification: Your invoice application {status}, bill amount {amount} yuan, {invoiceMsg}. <a href="javascript:void(0)" onclick="vm.readMessageAndJump('./invoiceDetails.html?id={id}' ,'{messageId}')">View</a>`,
    notify_00015: `Invoice Processing Notification: Your invoice application {status}, bill amount {amount} yuan, logistics information: {logisticsInfo}. <a href="javascript:void(0)" onclick="vm.readMessageAndJump('./invoiceDetails.html?id={id}' ,'{messageId}')">View</a>`,
    notify_00016: `{userName} hat die aus der Funktion {exportType} exportierten Bestelldaten am {exportTime} vorbereitet. Bitte laden Sie sie innerhalb von 7 Tagen herunter. Wenn sie abgelaufen sind, exportieren Sie die Bestellung bei Bedarf erneut. <a href="javascript:void(0)" onclick="vm.readMessageAnddownload('{downloadId}-{downloadType}-{createTime}' ,'{messageId}')">Herunterladen</a>`,
    notify_00017: 'Bitte warten, Download läuft',
    notify_00018: `{userName} konnte die Bestellung am {exportTime} nicht aus der Funktion {exportType} exportieren. Bitte <a href="javascript:void(0)" onclick="vm.readMessageAndJump('./bill.html#{type}' ,'{messageId}')">versuchen Sie es später erneut</a>!`,
    notify_00019: 'Datei ist abgelaufen',
    notify_00020: `Charging Stop Notification: Your platform usage fee is <span style="color:#FA545E">overdue</span> and not paid in time. Charging service has been stopped. <a href="javascript:void(0)" onclick="vm.readMessageAndJump('./selfHelpPayCost.html?id={id}#platformBillings' ,'{messageId}')">View</a>`,
    notify_00021: `Company-to-company transfer to recharge platform account successful! Recharge amount {amount} yuan. For details, please check in <a href="javascript:void(0)" onclick="vm.readMessageAndJump('./selfHelpPayCost.html?&payCostType=accountBalanceDetail' ,'{messageId}')">Balance Details</a>.`,
    notify_00022: `Expiration Reminder: The free IoT card network fee for {simCardNum} chargers <span style="color:#FA545E">has expired</span>. A fee bill will be generated on the 1st of next month. Please ensure sufficient balance in the platform account.`,
    notify_00023: `Non-XCharge charger model: {factory}-{sourceId} application successful. You can now add this model device on the platform. <a href="javascript:void(0)" onclick="vm.readMessageAndJump('./addDevice.html' ,'{messageId}')">Add Device</a>`,
    notify_00024: `Non-XCharge charger model: {factory}-{sourceId} application failed. Reason: {comment}.`,
    notify_00025: 'Gerätebenachrichtigung',
    notify_00026: 'Finanzielle Mitteilung',
    notify_00027: 'Sicherheitsbenachrichtigung',
    notify_00028: 'Asynchrone Aufgabe',
    notify_00029: 'Systemaktualisierung',
    notify_00030: 'Andere Benachrichtigungen',
  };
