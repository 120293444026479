export default {
    eur: "EUR",
    eur_name: "欧元",
    gbp: "GBP",
    gbp_name: "英镑",
    sek: "SEK",
    sek_name: "瑞典克朗",
    hcf: "HCF",
    hcf_name: "瑞士法郎",
    cny: "元",
    cny_name: "人民币",
    usd: "USD",
    usd_name: "美元",
    rub: "RUB",
    rub_name: "俄罗斯卢布",
    ils: "ILS",
    ils_name: "以色列新谢克尔",

    common_00001: "退出登录",
    common_00002: "YYYY/MM/DD",
    common_00003: "YYYY/MM/DD HH:mm:ss",
    common_00004: "场站中心",
    common_00005: "订单中心",
    common_00006: "维保中心",
    common_00007: "安全中心",
    common_00008: "用户中心",
    common_00009: "营销中心",
    common_00010: "数据中心",
    common_00011: "财务中心",
    common_00012: "流量中心",
    common_00013: "系统中心",
    common_00014: "监控概览",
    common_00015: "平台首页",
    common_00016: "整体概览",
    common_00017: "实时监控",
    common_00018: "年度统计",
    common_00019: "维修指标",
    common_00020: "充电异常订单",
    common_00021: "场站管理",
    common_00022: "创建场站",
    common_00023: "资费管理",
    common_00024: "资费模板",
    common_00025: "充电费付费方式管理",
    common_00026: "预付费",
    common_00027: "后付费",
    common_00028: "设备管理",
    common_00029: "占用费收取方式管理",
    common_00030: "云控车位锁",
    common_00031: "桩控车位锁",
    common_00032: "广告投放",
    common_00033: "设备广告投放",
    common_00034: "公众号广告投放",
    common_00035: "客户端场站展示标签",
    common_00036: "系统级营销类标签",
    common_00037: "场站级经营类标签",
    common_00038: "耗能管理",
    common_00039: "耗能统计",
    common_00040: "场站耗能管理",
    common_00041: "政府平台对接",
    common_00042: "加盟管理",
    common_00043: "加盟商邀请",
    common_00044: "充电订单",
    common_00045: "个人充电订单",
    common_00046: "团体充电订单",
    common_00047: "第三方流量充电订单",
    common_00048: "异常充电订单",
    common_00049: "全部充电订单",
    common_00050: "卡券销售订单",
    common_00051: "充电位占用账单",
    common_00052: "运行监控",
    common_00053: "实时监控",
    common_00054: "异常充电订单",
    common_00055: "维护指标",
    common_00056: "查询日志",
    common_00057: "设备异常日志",
    common_00058: "车辆充电日志",
    common_00059: "平台通信日志",
    common_00060: "故障处理",
    common_00061: "当前故障",
    common_00062: "电源模块保养",
    common_00063: "安全策略",
    common_00064: "站点车辆充电限制",
    common_00065: "高危车型管理",
    common_00066: "主动实时防护",
    common_00067: "黑名单",
    common_00068: "用户",
    common_00069: "用户管理",
    common_00070: "用户分类",
    common_00071: "分类管理",
    common_00072: "团体",
    common_00073: "集团支付团体管理",
    common_00074: "个人支付团体管理",
    common_00075: "全部团体管理",
    common_00076: "充电卡",
    common_00077: "卡组管理",
    common_00078: "即插即充卡管理",
    common_00079: "在线身份卡管理",
    common_00080: "离线启停卡管理",
    common_00081: "离线储值卡管理",
    common_00082: "车辆",
    common_00083: "车辆管理",
    common_00084: "微信客服",
    common_00085: "客服",
    common_00086: "会员",
    common_00087: "等级会员",
    common_00088: "卡券工具",
    common_00089: "抵用券",
    common_00090: "折扣券",
    common_00091: "代金券",
    common_00092: "电量包",
    common_00093: "服务费代金券",
    common_00094: "营销活动",
    common_00095: "储值送返活动",
    common_00096: "自由领取活动",
    common_00097: "注册送返活动",
    common_00098: "充电送返活动",
    common_00099: "累计送返活动",
    common_00100: "累计充电活动",
    common_00101: "裂变获客",
    common_00102: "邀请好友",
    common_00103: "卡券销售",
    common_00104: "卡券上架",
    common_00105: "卡券销售订单",
    common_00106: "销售商品消耗统计",
    common_00107: "卡券分发",
    common_00108: "渠道兑换码",
    common_00109: "渠道兑换记录",
    common_00110: "已兑换优惠消耗统计",
    common_00111: "效率分析",
    common_00112: "整体级分析",
    common_00113: "站点级分析",
    common_00114: "设备级分析",
    common_00115: "高峰时刻分析",
    common_00116: "平均功率分析",
    common_00117: "加盟商级分析",
    common_00118: "充电量分析",
    common_00119: "整体级分析",
    common_00120: "站点级分析",
    common_00121: "团体级分析",
    common_00122: "用户源级分析",
    common_00123: "加盟商级分析",
    common_00124: "收入分析",
    common_00125: "整体级分析",
    common_00126: "站点级分析",
    common_00127: "团体级分析",
    common_00128: "毛利率分析",
    common_00129: "用户源级分析",
    common_00130: "加盟商级分析",
    common_00131: "充电订单金额分析",
    common_00132: "用户分析",
    common_00133: "整体级分析",
    common_00134: "站点级分析",
    common_00135: "用户源级分析",
    common_00136: "用户贡献分析",
    common_00137: "用户RFM分析",
    common_00138: "加盟商级分析",
    common_00139: "卡券分析",
    common_00140: "销售趋势分析",
    common_00141: "销售分布分析",
    common_00142: "充值分析",
    common_00143: "充值金额分析",
    common_00144: "充值用户数分析",
    common_00145: "退款分析",
    common_00146: "退款金额分析",
    common_00147: "退款用户数分析",
    common_00148: "钱包分析",
    common_00149: "钱包余额趋势分析",
    common_00150: "钱包余额消耗方式分析",
    common_00151: "常用功能",
    common_00152: "个人发票申请",
    common_00153: "团体发票申请",
    common_00154: "钱包退款受理",
    common_00155: "订单查询",
    common_00156: "财务统计",
    common_00157: "充值金额",
    common_00158: "退款金额",
    common_00159: "用户钱包余额",
    common_00160: "卡券销售金额",
    common_00161: "财务设置",
    common_00162: "用户充值设置",
    common_00163: "用户退款设置",
    common_00164: "用户开票设置",
    common_00165: "第三方储备金管理",
    common_00166: "团体自助端开票设置",
    common_00167: "团体自助端充值设置",
    common_00168: "自助缴费",
    common_00169: "平台账单",
    common_00170: "账户管理",
    common_00171: "申请发票",
    common_00172: "发票明细",
    common_00173: "平台代开发票",
    common_00174: "开放引流",
    common_00175: "地图平台",
    common_00176: "运营车辆平台",
    common_00177: "聚合平台",
    common_00178: "政府平台",
    common_00179: "流量分析",
    common_00180: "第三方充电流量",
    common_00181: "操作日志",
    common_00182: "账号管理",
    common_00183: "权限管理",
    common_00184: "其他设置",
    common_00185: "API接口查看",
    common_00186: "运营商",
    common_00187: "信息设置",
    common_00188: "账号与权限",
    common_00189: "客户端设置",
    common_00190: "微信",
    common_00191: "支付宝",
    common_00192: "设备欢迎消息",
    common_00193: "添加车辆",
    common_00194: "编辑车辆",
    common_00195: "车牌号",
    common_00196: "车辆VIN码",
    common_00197: "手机号",
    common_00198: "车型|(选填)",
    common_00199: "所在地|(选填)",
    common_00200: "省市区",
    common_00201: "修改成功",
    common_00202: "站点信息",
    common_00203: "充电桩",
    common_00204: "开始时间",
    common_00205: "结束时间",
    common_00206: "电费",
    common_00207: "服务费",
    common_00208: "延时费",
    common_00209: "度",
    common_00210: "分钟",
    common_00211: "微信用户",
    common_00212: "添加",
    common_00213: "修改",
    common_00214: "删除",
    common_00215: "取消",
    common_00216: "删除成功",
    common_00217: "保存",
    common_00218: "充电站",
    common_00219: "充电口",
    common_00220: "创建订单",
    common_00221: "开始充电",
    common_00222: "结束充电",
    common_00223: "充电时长",
    common_00224: "充电电量",
    common_00225: "优惠",
    common_00226: "离线启停卡",
    common_00227: "离线储值卡",
    common_00228: "在线身份卡",
    common_00229: "详情",
    common_00230: "团体用户",
    common_00231: "第三方用户",
    common_00232: "卡券销售订单",
    common_00233: "通知",
    common_00234: "确定",
    common_00235: "全部",
    common_00236: "自营场站",
    common_00237: "加盟运营商场站",
    common_00238: "请输入充电站名称、地区",
    common_00239: "查找",
    common_00240: "清空",
    common_00241: "添加成功",
    common_00242: "小时",
    common_00243: "管理员",
    common_00244: "无",
    common_00245: "全部场站",
    common_00246: "修改密码",
    common_00247: "原登录密码",
    common_00248: "新的登录密码",
    common_00249: "使用字母和数字",
    common_00250: "当前账号登录密码",
    common_00251: "请输入原密码",
    common_00252: "请输入密码",
    common_00253: "请输入确认密码",
    common_00254: "两次密码不一致",
    common_00255: "查询",
    common_00256: "男",
    common_00257: "女",
    common_00258: "头像",
    common_00259: "昵称",
    common_00260: "性别",
    common_00261: "钱包余额",
    common_00262: "用户详情",
    common_00263: "查看充电订单",
    common_00264: "公众号",
    common_00265: "提示",
    common_00266: "取消下载",
    common_00267: "开始下载",
    common_00268: "再次输入",
    common_00269: "用户车辆认证",
    common_00270: "用户黑名单",
    common_00271: "重置",
    common_00272: "完成",
    common_00273: "是",
    common_00274: "否",
    common_00275: "参数为空",
    common_00276: "请输入小于100的正整数",
    common_00277: "运营商资费",
    common_00278: "充值",
    common_00279: "单位",
    common_00280: "必填",
    common_00281: "设置成功",
    common_00282: "操作",
    common_00283: "选择品牌",
    common_00284: "选择型号",
    common_00285: "手动输入",
    common_00286: "车品牌|型号",
    common_00287: "请先保存上一步操作",
    common_00288: "取消操作",
    common_00289: "直流",
    common_00290: "交流",
    common_00291: "网关",
    common_00292: "未启用",
    common_00293: "已启用",
    common_00294: "设置",
    common_00295: "推送结果",
    common_00296: "共推送了{allDevice}台设备，{sucDevice}台成功，{faiDevice}台失败",
    common_00297: "设备编号",
    common_00298: "设备名称",
    common_00299: "成功",
    common_00300: "失败",
    common_00301: "等待响应",
    common_00302: "离线",
    common_00303: "关闭",
    common_00304: "现有场站",
    common_00305: "推送资费",
    common_00306: "广告管理",
    common_00307: "设备保养",
    common_00308: "第三方功能",
    common_00309: "移除",
    common_00310: "选择",
    common_00311: "返回",
    common_00312: "开始日期",
    common_00313: "结束日期",
    common_00314: "选择站点",
    common_00315: "选择加盟商",
    common_00316: "选择设备型号",
    common_00317: "选择团体",
    common_00318: "整车厂平台",
    common_00319: "开始",
    common_00320: "运营平台",
    common_00321: "全屏",
    common_00322: "缩小",
    common_00323: "导出数据",
    common_00324: "场站地图",
    common_00325: "车辆MAC",
    common_00326: "选填",
    common_00327: "请选择",
    common_00360: "基本信息",
    common_00361: "优惠站点",
    common_00362: "团队成员管理",
    common_00363: "充电订单查询",
    common_00364: "充电统计",
    common_00365: "编辑",
    common_10000: '活动类型',
    common_10001: '活动数量',
    common_10002: '创建活动',
    common_10003: '维保首页',
    common_10004: '维保中心',
    common_10005: '场站维保分析',
    common_10006: "维保首页",
    common_10007: "自定义",
    common_10008: "财务首页",
    common_10009: "您确定访问老版V2运营平台？<br/>自2022年2月28日起，老版V2运营平台不再维护，请知晓！",
    common_10010: "老版V2入口",
    common_20008: '请输入站点名称',
    common_20009: "活动总览",
    common_20010: '能源运行监控',
    common_20011: '能源收益监控',
    common_20012: '储充桩管理',
    common_20013: '能源统计',
    common_20014: '储能',
    common_20015: '统计',
    common_20016: '能源中心',
    common_20017: 'Max 功率模式',
    common_20018: 'Eco 经济模式',
    common_20019: 'Auto 自动模式',
    common_20020: '空闲',
    common_20021: '储能补电中',
    common_20022: '储能放电中',
    common_20023: '分钟充满',
    common_20024: '当前工作模式',
    common_20025: '模式',
    common_20026: '储充',
    common_20027: '储能空闲中',
    common_20028: '储能故障',
    common_20029: 'Eco经济模式会根据您的电网资费谷时段和尖峰时段运行。但需要您选择平时段成本更接近谷时段成本还是尖峰时段成本。',
    common_20030: '更接近谷时段',
    common_20031: '更接近尖峰时段',
    common_20032: '发现您没有设置该场站的电网资费',
    common_20033: '选择电网资费',
    common_20034: '新建电网资费',
    common_20035: '电网资费必须设置峰、平、谷三个或尖、峰、平、谷四个标签时段。',
    common_20036: '请选择平时段成本更接近哪个时段',
    common_20037: '电网资费必须设置峰、平、谷三个或尖、峰、平、谷四个标签时段',
    common_20038: '经济模式',
    common_20039: '高效模式',
    common_20040: '储充空调运行模式',
    common_20041: '储充工作模式',
    common_20042: "车端功率需求为第一优先级，随充随补，优先使用DCDC模块给车充电。",
    common_20043: "弥补场站输出功率不足的问题，使用储能来提供需求缺口的功率。",
    common_20044: "根据平台下发的电价峰谷状态实现CPO成本最低。",
    common_20045: "削峰填谷，谷电电价时给储能补电，峰电电价时使用储能来给车充电。",
    common_20046: "根据平台发放的电费策略自动控制充放电。",
    common_20047: "自动调整功率需求和电价成本，满足车充电功率的同时，有余力可以进行储能补电。",
    common_20048: "策略介绍",
    common_20049: "应用场景",
    common_20050: "请输入卡号",
    common_20051: "请输入来源",
    common_20052: "故障",
    common_20053: "待机",
    common_20054: "今日",
    common_20055: "昨日",
    common_20056: "环比前日",
    common_20057: "工作模式介绍",


    common_22000: "白名单",
    common_22001: "SOC限制",


    // 工单
    common_31000: "工单系统",
    common_31001: "工单管理",
    common_31002: "工单分类",
    common_31003: "工单统计",
    common_31004: "知识库",
    common_31005: "请先绑定公众号",
    common_31006: "发起工单",
    common_31007: "自动通知",
    common_40000: '设备寿命保养',
    common_41000: '请填写正确',
    common_41001: '自动工单触发器',
    common_41002: '自定义场景策略',
    common_41003: '触发日志',

    // 新增
    common_50004: "运营商管理",
    common_50005: "场站管理",
    common_50006: "充电桩管理",
    common_51000: "订单列表",
    common_51001: "异常订单",
    common_51002: "桩责异常订单",
    common_20053: "待机",
    common_62001: "处理建议:",
    common_62002: "故障处理建议:",

    // 资费推送结果补充
    common_45000: "站点名称",
    common_45001: "设备数量",
    common_45002: "设备推送结果",
    common_45003: "直",
    common_45004: "交",
    common_45005: "储充",
    common_45006: "推送中",
    common_45007: "推送失败",
    common_45008: "修改团体",

    //菜单栏增加菜单模块
    common_46000: "车主端小程序",
    common_46001: "首页Banner",
    common_46002: "活动发布",
    common_46003: "关注公众号",
    common_46004: "只能上传图片格式",
    common_46005: "上传文件大小不能超过{ maxSize }MB!",
    common_46006: "活动ID",
    common_46007: "公众号链接",
    common_46008: "活动名称",
    common_46009: "活动状态",
    common_46010: "活动ID",
    common_46011: "活动时间",
    common_46012: "未开始",
    common_46013: "进行中",
    common_46014: "已暂停",
    common_46015: "已结束",
    common_46016: "全部",
    common_46017: "名称",
    common_46018: "选择活动",
    common_46019: "选择活动类型",
    common_46020: "充值送返",
    common_46021: "自由领取",
    common_46022: "邀请好友",
    common_46023: "购买卡券",
    // 到期倒计时
    common_11000: "已停止充电服务",
    common_110001: "本月账单涉及的桩已停止充电服务",
    common_11001: "剩余{0}天",
    common_11002: "自动续费扣款失败, {0}天后停止充电服务",
    common_11003: "平台账单扣款失败, {0}后账单涉及的桩将停止充电服务",
    common_11004: "平台使用期限已到期",
    common_11005: "自动续费",
    common_11006: "暂无数据",
    common_45009: "开通服务",
    common_45010: "隐私声明",
    // 卡券
    common_00366: "卡券消耗分析",
    common_45011: "加盟运营商用户，无权限查看",
    // 充电口状态
    common_60000: "占用中",
    // common_60001: "未启用",
    common_60002: "异常",
    common_60003: "故障/异常/离线",
    common_60004: "预约中",
    common_60005: "查看",
    common_60006: "已插枪",
    common_60007: "充电中",
    common_60008: "待挪车",
    common_60009: "待拔枪",
    common_60010: "微网能流概览",
    common_60011: "能源微网设置",
    // 资费弹窗
    common_61000: "资费名称",
    common_61001: "请输入资费名称",
    common_61002: "该资费名称已被占用",
    common_61003: "充电资费",
    common_61004: "电网资费",
    common_61005: "使用货币",
    common_61006: "添加时段",
    common_61007: "最多可以添加{0}个时段",
    common_61008: "开始时间",
    common_61009: "结束时间",
    common_61010: "电网电费({type}/{unit})",
    common_61011: "充电电费\n({type}/{unit})",
    common_61012: "服务费\n({type}/{unit})",
    common_61013: "延时费\n({type}/分钟)",
    common_61014: "谷峰平时段",
    common_61015: "尖峰平谷对应价格",
    common_61016: "尖峰谷平时段",
    common_61017: "尖",
    common_61018: "峰",
    common_61019: "平",
    common_61020: "谷",
    common_61021: "请填写资费标准",
    common_61022: "请选择峰谷平时段标签",
    common_61023: "资费时段需从0开始至24结束",
    common_61024: "请填写正确的资费标准",
    common_61025: "操作成功",
    common_61026: "操作失败",
    common_61027: "上一步",
    common_61028: "下一步",
    common_61029: "未定义",
    common_61030: "请先设置场站资费",
    common_61031: "应用范围",
    common_61032: "场站公共资费",
    common_61033: "团体资费",
    common_61034: "第三方流量资费",
    common_61035: "推送给交流桩",
    common_61036: "推送给直流桩",
    common_61037: " ({0}场站)",
    common_61038: "该资费应用范围如下，是否确认继续修改？",
    // common_61039: "最多可以添加{0}个时段",
    common_61040: "新建资费",
    common_61041: "修改资费",
    common_61042: "0-24小时单一时段，请选择‘平’时段标签",
    common_61043: "多时段请选择两个以上时段标签",
    common_61044: "电费价格对应时段标签有误，请检查对应电费应该是‘尖>峰>平>谷’",
    common_61045: "工作模式",
    common_61046: "储充桩工作模式",
    common_61047: "了解更多",
    common_61048: "储充桩空调工作模式",
    common_61049: "当前场站没有选择电网资费，请先选择场站电网资费，再切换工作模式",
    common_61050: "当前电网资费只有一种时段标签，不能选择“Eco”和“Auto”模式",
    common_61051: "当前电网资费只有两种时段标签，不能选择“Auto”模式",
    common_61052: "当前电网资费时段和工作模式不匹配，请修改电网资费或工作模式",
    common_61053: "推送成功",
    common_61054: "设备离线",
    common_61055: "选择资费",
    common_61056: "该站点需要选择{type}的资费类型，当前没有该资费模板，请新建资费。",
    common_61057: "资费",
    common_61058: "推送对象",
    common_61059: "请选择站点",
    common_61060: "直流资费",
    common_61061: "交流资费",
    common_61062: "公共资费",
    common_61063: "团体",
    common_61064: "第三方",
    common_61065: "是否确认绑定该资费到",
    common_61066: "站点",
    common_61067: "的",
    common_61068: "已绑定",
    common_61069: "重试绑定",
    common_61070: "请设置推送范围",
    common_61071: "请选择团体",
    common_61072: "请选择第三方流量",
    common_61073: "自定义工作模式",
    common_61074: "请选择自定义工作模式时间计划",
    common_61075: "已应用",
    common_61076: "未应用",
    common_61077: "推送",
    common_61078: "并网电价({type}/{unit})",
    common_61079: "启充失败日志",
    common_61080: "允许使用光伏电力",
    common_61081: "当选择是时，表示允许通过光伏给储能或给车充电。当选择否时，表示不允许使用光伏的电力。",
    common_61082: "场站光伏最大总功率",
    common_61083: "接收光伏电力时间",
    common_61084: "光伏设置",
    common_61085: "光伏管理",
    common_61086: "供电中",
    common_61087: "未供电",
    common_61088: "今日累计供电",
    // 自定义模式
    common_70000: "Custom自定义模式",
    common_70001: "查看所有时间计划",
    common_70002: "运营商根据自己的经验，自由按时段配置运行模式。",
    common_70003: "电网电价不时变动，而且场站的忙闲情况也是周期性有变化，或者场站的能源需求周期性调整等需求定制化能源调度的情况。",
    common_70004: "新建",
    common_70005: "自定义工作模式时间计划（年）",
    common_70006: "时间计划名称",
    common_70007: "请填写时间计划名称",
    common_70008: "添加日期区间",
    common_70009: "周计划",
    common_70010: "月",
    common_70011: "日",
    common_70012: "复制",
    common_70013: "电能使用方式",
    common_70014: "编辑0-24小时计划",
    common_70015: "添加日期区间",
    common_70016: "添加周计划",
    common_70017: "NZS支持Grid to Vehicle（简写为GtoV）、Battery to Vehicle（简写为BtoV）、Grid to Battery（简写为GtoB）、Battery to Grid（简写为B2G）、PV to Battery（简写为P2B）几种电能使用方向，请根据经验，划分时段，来选择电能的使用方式。",
    common_70018: "例如：GtoV > BtoV > GtoB的优先级排列，表示”没车充电的时候给电池补电，有车充电的时候优先使用电网，后使用电池“；BtoV > GtoV > GtoB的优先级排列，表示”没车充电的时候给电池补电，有车充电的时候优先使用电池，后使用电网“，以此类推",
    common_70019: "添加时间段",
    common_70020: "电能使用方式",
    common_70021: "请输入名称",
    common_70022: "到",
    common_70023: "周一",
    common_70024: "周二",
    common_70025: "周三",
    common_70026: "周四",
    common_70027: "周五",
    common_70028: "周六",
    common_70029: "周日",
    common_70030: "复制成功",
    common_70031: "粘贴",
    common_70032: "已复制",
    common_70033: "按照日期区间和日期区间中的每周计划，来制定一年中的设备工作模式计划，推送到NZS桩端后，会按照该计划，每年循环执行。如果有修改，需要在更改该计划后，重新推送到NZS桩端执行。",
    common_70034: "时间日期填写不正确",
    common_70035: "电能使用方式不能为空",
    common_70036: "时段需从0开始至24结束",
    common_70037: "请填写0-24小时计划",
    // 全局搜索
    common_63000: "搜索",
    common_63001: "请输入订单编号",
    common_63002: "请输入昵称/手机号/车牌号/车架号",
    common_63003: "请输入设备名称/设备序列号",
    common_63004: "请输入场站名称",
    common_63005: "设备",
    common_63006: "请输入团体名称",
    common_63007: "订单",
    common_63008: "场站",
    common_63009: "即插即充卡",
    common_63010: "请输入内容",
    common_63011: "已存在",
    common_63012: "已失效",
    common_63013: "添加设备",

    common_62000: "特权会员",
    common_62001: "处理建议:",
    common_62002: "故障处理建议:",
    // 安全中心
    common_61100: "安全实时防护",
    common_61101: "安全评估日志",
    common_61102: "安全报告",
    common_61103: "车辆限制充电",
    common_61104: "安全概览",
    common_61105: "限制/禁止充电",
    common_61106: "【实时防护】停充",
    common_61107: "【实时防护】降功率",
    common_61108: "【提前预警】上次评估高风险",
    common_61109: "【提前预警】上次评估中风险",
    common_61110: "【提前预警】拒充-禁止充电",
    common_61111: "【提前预警】限充-限制充电",
    // 限制车辆充电
    common_61112: "修改信息",
    common_61113: "添加信息",
    common_61114: "选择车辆",
    common_61115: "填写结束日期及原因",
    common_61116: "车牌号/VIN",
    common_61117: "当前列表只包含未限制充电或未禁止充电的车辆",
    common_61118: "车辆型号",
    common_61119: "车主",
    common_61120: "限制充电",
    common_61121: "禁止充电",
    common_61122: "SOC限制",
    common_61123: "1、车辆与场站同时限制SOC时，以最低SOC限制为限制标准。",
    common_61124: "2、只限制直流桩。",
    common_61125: "请输入正整数,1到99",
    common_61126: "最高",
    common_61127: "固定时间",
    common_61128: "长期",
    common_61129: "（直到手动移除为止）",
    common_61130: "原因",
    common_61131: "请输入原因，最多50字",
    common_61132: "请填写必填项",
    common_61133: "设置不符合规则",
    common_61134: "立即推送",
    common_61135: "计划推送",
    common_61136: "推送成功后设备会以新的资费标准结算充电费用，推送结果中，如有推送失败的设备可尝试点击‘重新推送’按钮。",
    common_61137: "根据您设置的资费应用范围、推送时间，进行自动推送。如果有设备推送失败，系统会自动重新推送五次，如果最终仍推送失败则停止自动推送，请设置计划推送后尽快查看推送结果。可在资费管理-推送任务中查看推送结果，在任务详情中查看修改后的资费详情！",
    common_61138: "添加到推送任务",
    common_61139: "推送时间",
    common_61140: "只可选择当前时间之后的时间",
    common_61141: "已添加计划推送任务，可跳转“推送任务”页面查看。可在推送任务详情中查看修改后的资费详情！",
    common_61142: "重新推送",
    common_61143: "推送任务",
    common_61144: "请设置资源应用的范围，勾选后当前资费将设置到所选设备上，需要您推送成功后，设备才能以新的资费进行充电订单结算。注意：非智充桩可能会不支持该操作导致推送失败。",
    common_61145: "参数错误",
    common_61146: "数据报告",
    common_61147: "全选",
    common_61148: "推送全部交流桩",
    common_61149: "推送全部直流桩",
    common_61150: "是否确认绑定该资费到所有交流桩",
    common_61151: "是否确定绑定改资费到所有直流桩",
    common_61152: "全部绑定成功",
    common_61153: "部分站点绑定失败，请单独重试",
    common_61154: "绑定中，请勿关闭弹窗",
    common_61155: "周报",
    common_61156: "月报",
    common_61157: "请输入",
    common_61158: "充电送返",
    common_61159: "【实时防护】告警",
    common_61160: "请正确填写功率",
    common_61161: "请正确填写时间",
    common_61162: "安全充电限制",
    common_61163: "长时低功充电限制",
    common_61164: "该车上次安全评估不安全，需要尽快检修车辆。",
    common_61165: "该车上次安全评估亚安全，请注意车辆使用安全。",
    common_61166: "该车已加入禁止充电名单，拒绝充电。",
    common_61167: "该车已加入限充名单，限充SOC {0}%",
    common_61168: "该资费在推送任务中还有等待执行的计划推送任务。为确保运营平台、场站设备、第三方流量等使用资费方一致无误差，请您根据需要，新建资费然后再计划推送或在资费任务中删除该资费计划推送任务然后再计划推送。",
    common_61169: "已知晓",
    common_61170: "",
    common_80000: "最少6位，且至少包含1个数字及1个字母以及1个特殊字符",
    //组队打榜活动
    common_80001: "组队打榜活动",
    common_80002: "组队打榜",
    common_80003: "安全围栏",
    common_80004: "充电安全设置",
    common_80005: "剩余",
    common_80006: "超出",
    common_80007: "{type}当前不支持多时段资费设置。请修改资费为单一时段资费。",
    common_80008: "去修改资费",
    common_80009: "请确认该资费没有被应用在有C6AM,C7NE,C7NA设备的场站，C6AM,C7NE,C7NA当前不支持多时段资费设置。",
    // 腾讯地图
    common_80010: "选择充电站位置坐标",
    common_80011: "地址、街道、房屋名称",
    common_80012: "查 找",
    common_80013: "名 称",
    common_80014: "详细地址",
    common_80015: "当前位置",
    common_80016: "取 消",
    common_80017: "使用此位置",
    common_80018: "用户排行",

    common_71100: "入场须知",
    common_71101: "问题",
    common_71102: "建议",
    common_71003: "联系人",
    common_71004: "添加问题不能为空",
    common_71005: "解决方案",

    //工单编辑器
    common_71006: "上传文件",
    common_71007: "视频上传",
    common_71008: "添加视频链接",
    common_71009: "请输入视频链接",
    common_71010: "将文件拖到此处，或",
    common_71011: "点击上传",
    common_71012: "只能上传MP4文件，且不超过{maxSize}M",
    common_71013: "本地视频上传",
    common_71014: "没有设置发送到智充ERP系统的关联信息，请联系管理员或您的销售顾问进行设置。",
    common_71015: "该运营商没有设置发送到金蝶系统的关联信息，请联系管理员进行设置。",
    common_71016: "使用备件",
    common_71017: "请填写生效时段",
    // menu
    common_71018: "团体管理",
    common_71019: "充电卡管理",
    common_71020: "财务概况",
    common_71021: "工单分类管理",
    common_71022: "菜单",

    //急停恢复
    common_13001: "急停恢复",
    common_13002: "此操作要求设备固件版本在4.0.0以上",
    common_13003: "最近一次自检发现设备部件异常，请及时检修。",
    common_13004: "车端暂停",

    common_72000: "营销引导",
    common_72001: "获取流量引导",
    common_72002: "短时间内增加现金流",
    common_72003: "车主常来充电",
    common_72004: "",
    // 添加站点弹窗
    common_72005: "站点状态",
    common_72006: "已启用",
    common_72007: "未启用",
    common_72008: "对外开放",
    common_72009: "开放",
    common_72010: "不开放",
    common_72011: "添加站点",
    common_72012: "交",
    common_72013: "自定义场站",
    common_72014: "添加场站",
    common_72015: "批量移除",
    common_72016: "场站状态",
    common_72017: "输入站点名、运营商名",
    common_72018: "查询",
    // #10465
    common_73000: "桩责告警码（导致桩不可用）",
    common_73001: "桩责告警码（导致充电结束）",
    common_73002: "充电时长",
    common_73003: "充电功率",
    common_73004: "充电电压",
    common_73005: "充电电流",
    common_73006: "充电车辆SOC",
    common_73007: "单设备订单异常",
    common_73008: "拖拽",
    common_73009: "结束充电",
    common_73010: "拉黑微信用户",
    common_73011: "挂失充电卡",
    common_73012: "禁止车辆充电",
    common_73013: "限制车辆充电",
    common_73014: "通知管理员",
    common_73015: "设备重启",
    common_73016: "限制最大充电功率",
    common_73017: "自动发起工单",
    common_73018: "角色",
    common_73019: "账号",
    common_73020: "短信通知",
    common_73021: "微信通知",
    common_73022: "平均充电功率",
    common_73023: "根据设备故障码触发工单",
    common_73024: "长时低功充电限制",
    common_73025: "限制充电桩最大允许充电SOC",
    common_73026: "查找呆滞桩并触发工单",
    common_73027: "查找订单异常桩并触发工单",
    common_73028: "桩企",
    common_73029: "去查看",
    common_73030: "删除策略后不可恢复，是否确定？",
    common_73031: "自定义场景策略：",
    common_73032: "邮件通知",
    // #10825
    common_73033: "密码安全性提升",
    common_73034: "尊敬的用户，为了保障您的账户安全，我们检测到您当前使用的密码为弱密码，存在一定的安全风险。为了更好地保护您的个人信息和账户数据，请您立即修改密码，设置一个强度更高的密码。",
    common_73035: "用户名",
    common_73036: "当前密码",
    common_73037: "新密码",
    common_73038: "确认新密码",
    common_73039: "新密码要求：<br/>·长度至少为 6 个字符。<br/>·包含字母、数字和特殊字符（如!@#$%^&*()_+ 等）。",
    common_73040: "请填写完整",
    common_73041: "密码不一致，请重新输入",
    common_73042: "密码错误",
    common_73043: "请输入有效密码",
    common_73044: "储能故障",
    common_73045: "异常",
    // 11005
    common_74000:"桩端日志",
    common_74001:"上传日志",
    common_74002:"任务发起时间",  
    common_74003:"任务状态",
    common_74004:"当前状态时间",
    common_74005:"操作员",
    common_74006:"是否确认上传日志",
    common_74007:"执行中",
    common_74008:"执行成功",
    common_74009:"执行失败",
    common_74010:"操作成功",
    common_74011:"已取消",
    common_74012:"操作失败",
    common_74013:"重试",
    common_74014:"下载",




}  
